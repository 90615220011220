import { EmoviToGuess } from "../interfaces/EmoviToGuess";

export const DAILY_EMOVI: Record<string, EmoviToGuess> = {
    "2022-07-17": {id: "tt0114709", emojiText: "🥔🤠👨‍🚀🐊🐖🐶"},
    "2022-07-18": {id: "tt0167260", emojiText: "💍🌋🧙‍♂️👑"},
    "2022-07-19": {id: "tt0103639", emojiText: "🧞‍♂️🪔🐒👸🤴"},
    "2022-07-20": {id: "tt0211915", emojiText: "👩‍🦰🎠🗼🥖🇫🇷"},
    "2022-07-21": {id: "tt1745960", emojiText: "✈️🇺🇸🕶️"},
    "2022-07-22": {id: "tt0109830", emojiText: "🏃🍫🦐"},
    "2022-07-23": {id: "tt0120382", emojiText: "🙍‍♂️🎥⛵😨📺"},
    "2022-07-24": {id: "tt4633694", emojiText: "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"},
    "2022-07-25": {id: "tt0090605", emojiText: "👽🤰🩸😱"},
    "2022-07-26": {id: "tt0062622", emojiText: "👁️🖥️🛰️🚀"},
    "2022-07-27": {id: "tt0054215", emojiText: "🔪🚿🧓"},
    "2022-07-28": {id: "tt0101414", emojiText: "🌹👸🧌"},
    "2022-07-29": {id: "tt0264464", emojiText: "🏃‍♂️✈️💰🏃"},
    "2022-07-30": {id: "tt0045152", emojiText: "☔🎤😃"},
    "2022-07-31": {id: "tt0107290", emojiText: "🦟💉🥚🦕🦖🚨🏃‍♂️"},
    "2022-08-01": {id: "tt0993846", emojiText: "🐺🧱🛣️〽️🏦💵"},
    "2022-08-02": {id: "tt0382932", emojiText: "🐭💆‍♂️👨‍🍳🍲"},
    "2022-08-03": {id: "tt10648342", emojiText: "🦸🔨❤️➕⛈️"},
    "2022-08-04": {id: "tt0482571", emojiText: "🏃🚪🎩🚪🏃▶️👬"},
    "2022-08-05": {id: "tt2380307", emojiText: "🎸💀👦🇲🇽"},
    "2022-08-06": {id: "tt0107048", emojiText: "🔄⏰🦔"},
    "2022-08-07": {id: "tt0133093", emojiText: "🕵️🕵️‍♀️🤜🤵🔌💊"},
    "2022-08-08": {id: "tt1130884", emojiText: "👮‍♂️💊🏝️😨"},
    "2022-08-09": {id: "tt2293640", emojiText: "🤓🤓🤓🍌🧒"},
    "2022-08-10": {id: "tt0114369", emojiText: "😋😛😴🤢😡😎🤤👀"},
    "2022-08-11": {id: "tt5311514", emojiText: "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"},
    "2022-08-12": {id: "tt0119217", emojiText: "🧹🏫👨‍🏫🧠🍎"},
    "2022-08-13": {id: "tt12412888", emojiText: "🦔👟✌️"},
    "2022-08-14": {id: "tt0088763", emojiText: "🚗🔙⌚👨‍🔬🙍"},
    "2022-08-15": {id: "tt0435761", emojiText: "👨‍🚀🤠🏫🍓🧸"},
    "2022-08-16": {id: "tt0116629", emojiText: "🇺🇸🎆👽👊"},
    "2022-08-17": {id: "tt0325980", emojiText: "🏴‍☠️🌊💀⚔️⚫📿"},
    "2022-08-18": {id: "tt6467266", emojiText: "🐷🦍🦁🎹🎧🦔"},
    "2022-08-19": {id: "tt1160419", emojiText: "👩‍👦🗡️⏳🐛🪐"},
    "2022-08-20": {id: "tt0066921", emojiText: "👁️🎩🦯🥛🍊"},
    "2022-08-21": {id: "tt1049413", emojiText: "👴🧒🎈🏠🏞️🐕🔼"},
    "2022-08-22": {id: "tt2582802", emojiText: "🥁👨‍🦲⏱️🩸🚗📁🎶"},
    "2022-08-23": {id: "tt0120338", emojiText: "💑🚢🧊🥶👵"},
    "2022-08-24": {id: "tt2096673", emojiText: "😡😭😊🤢😱🧠"},
    "2022-08-25": {id: "tt0050083", emojiText: "😠😠😠😠😠😠😠😠😠😠😠😠"},
    "2022-08-26": {id: "tt1396484", emojiText: "🤡👿🎈"},
    "2022-08-27": {id: "tt0073195", emojiText: "🦈😱"},
    "2022-08-28": {id: "tt0137523", emojiText: "🥊♣️🧼"},
    "2022-08-29": {id: "tt1877830", emojiText: "🦇🐱🐧❓"},
    "2022-08-30": {id: "tt0088247", emojiText: "🤖🕶️🔫"},
    "2022-08-31": {id: "tt0110413", emojiText: "🧔🔫🥛👧"},
    "2022-09-01": {id: "tt0110912", emojiText: "💼🕺💉💃☕⌚"},
    "2022-09-02": {id: "tt0083658", emojiText: "🗡️🏃‍♂️"},
    "2022-09-03": {id: "tt4729430", emojiText: "📫🧓🔨🧸🛷"},
    "2022-09-04": {id: "tt2382320", emojiText: "🚫🕐💀"},
    "2022-09-05": {id: "tt1201607", emojiText: "👦⚡☠️⚔️2️⃣"},
    "2022-09-06": {id: "tt2024544", emojiText: "🕛🗓️⛓️"},
    "2022-09-07": {id: "tt0095016", emojiText: "🗼🎄👨🔫"},
    "2022-09-08": {id: "tt0041959", emojiText: "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"},
    "2022-09-09": {id: "tt0070047", emojiText: "✝️🤮🙏"},
    "2022-09-10": {id: "tt0266697", emojiText: "👱‍♀️⛩️⚔️"},
    "2022-09-11": {id: "tt0081505", emojiText: "🪓😈🛀"},
    "2022-09-12": {id: "tt10298810", emojiText: "👨‍🚀🌌"},
    "2022-09-13": {id: "tt0167404", emojiText: "👁️👀💀🧑‍🤝‍🧑"},
    "2022-09-14": {id: "tt9032400", emojiText: "🦸‍♂️🌍♾️"},
    "2022-09-15": {id: "tt0317705", emojiText: "👨‍👩‍👧‍👦🦸ℹ️"},
    "2022-09-16": {id: "tt6751668", emojiText: "👨‍👩‍👧‍👦💸🇰🇷"},
    "2022-09-17": {id: "tt0266543", emojiText: "🔎🐟"},
    "2022-09-18": {id: "tt4154756", emojiText: "♾️⚔️"},
    "2022-09-19": {id: "tt0075148", emojiText: "🥊"},
    "2022-09-20": {id: "tt0112573", emojiText: "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"},
    "2022-09-21": {id: "tt0816692", emojiText: "🚀🌊⏲️🌽"},
    "2022-09-22": {id: "tt0102926", emojiText: "🤐🐑"},
    "2022-09-23": {id: "tt0032138", emojiText: "🐶👠👠🌪️🏠"},
    "2022-09-24": {id: "tt0884328", emojiText: "🌫️🛒🌫️"},
    "2022-09-25": {id: "tt1375666", emojiText: "💤🌀⤴️"},
    "2022-09-26": {id: "tt0060196", emojiText: "👍👎👹"},
    "2022-09-27": {id: "tt0198781", emojiText: "🟢👁️🟢👧👹"},
    "2022-09-28": {id: "tt0892769", emojiText: "⚫🐉🦷"},
    "2022-09-29": {id: "tt0910970", emojiText: "🌍🗑️🤖🌱❤️"},
    "2022-09-30": {id: "tt8503618", emojiText: "🇺🇸🎼🗽🎵🔫"},
    "2022-10-01": {id: "tt0457430", emojiText: "👁️👐👁️"},
    "2022-10-02": {id: "tt0118715", emojiText: "🎳🥛💼🩲"},
    "2022-10-03": {id: "tt0169547", emojiText: "🇺🇸💅🌹🌹🌹🌹🌹🌹"},
    "2022-10-04": {id: "tt0056172", emojiText: "🏇🏜️🗡️"},
    "2022-10-05": {id: "tt1345836", emojiText: "🦇🤵⬆️"},
    "2022-10-06": {id: "tt0102926", emojiText: "🔇🐏"},
    "2022-10-07": {id: "tt0034583", emojiText: "🇫🇷💖🚂😒🕌☕🎹✈️"},
    "2022-10-08": {id: "tt1028532", emojiText: "🐶🙍‍♂️🚋⏱️"},
    "2022-10-09": {id: "tt0119698", emojiText: "🐺👸🩸🐗🐛"},
    "2022-10-10": {id: "tt0090605", emojiText: "🌐👩👧🐱💥👽"},
    "2022-10-11": {id: "tt0053291", emojiText: "👨‍🦰👨‍🦱👠💄👗"},
    "2022-10-12": {id: "tt0110357", emojiText: "🦁🐆🐗👑"},
    "2022-10-13": {id: "tt0245429", emojiText: "🐉😱👹👵⛩️"},
    "2022-10-14": {id: "tt0117951", emojiText: "💉👬🧑‍🤝‍🧑💊🇬🇧🤪🤮"},
    "2022-10-15": {id: "tt7657566", emojiText: "👨🔎🇪🇬⛴️☠️"},
    "2022-10-16": {id: "tt0096283", emojiText: "▶️🚪🏠🐻"},
    "2022-10-17": {id: "tt4513678", emojiText: "🙍🙍🙍🙍👻🧹"},
    "2022-10-18": {id: "tt1464335", emojiText: "🗺️🧗‍♂️🚁💰"},
    "2022-10-19": {id: "tt0338013", emojiText: "💑💔😭💵👨‍🔬😴🧠💻🕵️🙂"},
    "2022-10-20": {id: "tt0068646", emojiText: "🐴🛏️🩸"},
    "2022-10-21": {id: "tt0082971", emojiText: "🏜️🕌💼💀🤠"},
    "2022-10-22": {id: "tt6264654", emojiText: "🎮🔫🧑‍💻⌨️🏦"},
    "2022-10-23": {id: "tt0017136", emojiText: "🤖🌆🇩🇪🎥"},
    "2022-10-24": {id: "tt0120689", emojiText: "💀🚶‍♂️🟩🟩🟩"},
    "2022-10-25": {id: "tt0111161", emojiText: "👨‍⚖️😧♟️⛏️👋"},
    "2022-10-26": {id: "tt0172495", emojiText: "🤼‍♂️🏟️⚔️👎"},
    "2022-10-27": {id: "tt1201607", emojiText: "👦⚡🔺💫🚫👃☠️"},
    "2022-10-28": {id: "tt8772262", emojiText: "💐🔪🐻"},
    "2022-10-29": {id: "tt1853728", emojiText: "🦷🤠🧐🏇⛓️"},
    "2022-10-30": {id: "tt0114814", emojiText: "👮‍♂️🤬🔫🕵️‍♂️❔"},
    "2022-10-31": {id: "tt0095327", emojiText: "👨‍👧⚰️🌌🌂🇯🇵"},
    "2022-11-02": {
        id: "tt7286456",
        emojiText: "🔫🤡🃏"
    },
    "2022-11-03": {
        id: "tt0253474",
        emojiText: "🧑🎹"
    },
    "2022-11-04": {
        id: "tt11703710",
        emojiText: "🎩👒📽️🎙️🇬🇧"
    },
    "2022-11-05": {
        id: "tt0057115",
        emojiText: "🇩🇪🕳️⛏️🏍️🚞"
    },
    "2022-11-06": {
        id: "tt1130884",
        emojiText: "🏝️🧐🏨"
    },
    "2022-11-07": {
        id: "tt0100157",
        emojiText: "⌨️👩‍🦼🔨👩‍🦰"
    },
    "2022-11-08": {
        id: "tt15521050",
        emojiText: "❤️🍦"
    },
    "2022-11-09": {
        id: "tt0076759",
        emojiText: "⭐🔫💣"
    },
    "2022-11-10": {
        id: "tt0081398",
        emojiText: "😡🐂"
    },
    "2022-11-11": {
        id: "tt0092099",
        emojiText: "🔝🔫🛩"
    },
    "2022-11-12": {
        id: "tt0105236",
        emojiText: "🚧🌊🚧🐶"
    },
    "2022-11-13": {
        id: "tt1050263",
        emojiText: "❄◽"
    },
    "2022-11-14": {
        id: "tt0106965",
        emojiText: "🆓🐳"
    },
    "2022-11-15": {
        id: "tt0397892",
        emojiText: "🐶⚡"
    },
    "2022-11-16": {
        id: "tt11245972",
        emojiText: "😱🔪"
    },
    "2022-11-17": {
        id: "tt0118571",
        emojiText: "🛩🇺🇸1"
    },
    "2022-11-18": {
        id: "tt0119654",
        emojiText: "👨🏻⤵⬛👽"
    },
    "2022-11-19": {
        id: "tt0119695",
        emojiText: "💰👄"
    },
    "2022-11-20": {
        id: "tt0120632",
        emojiText: "🏙👼🏻👼🏻"
    },
    "2022-11-21": {
        id: "tt0120812",
        emojiText: "🚗💨🕑"
    },
    "2022-11-22": {
        id: "tt0250494",
        emojiText: "⚖👩🏼👛🎥"
    },
    "2022-11-23": {
        id: "tt0375679",
        emojiText: "🚙💥🚗"
    },
    "2022-11-24": {
        id: "tt0437405",
        emojiText: "📄👨🏻"
    },
    "2022-11-25": {
        id: "tt0417217",
        emojiText: "2➡💰"
    },
    "2022-11-26": {
        id: "tt0112431",
        emojiText: "🐷💬🎥"
    },
    "2022-11-27": {
        id: "tt0086034",
        emojiText: "🐙🐱"
    },
    "2022-11-28": {
        id: "tt6139732",
        emojiText: "👳🏻🔮🌙"
    },
    "2022-11-29": {
        id: "tt0053285",
        emojiText: "💤👩🏻"
    },
    "2022-11-30": {
        id: "tt0096244",
        emojiText: "🍹🌄"
    },
    "2022-12-01": {
        id: "tt0097239",
        emojiText: "🚘👵🏻🌼"
    },
    "2022-12-02": {
        id: "tt0253474",
        emojiText: "👨🏻🎹"
    },
    "2022-12-03": {
        id: "tt9347730",
        emojiText: "🍬👨🏻"
    },
    "2022-12-04": {
        id: "tt0061852",
        emojiText: "🌴📖"
    },
    "2022-12-05": {
        id: "tt0111257",
        emojiText: "💣🚌💨💨"
    },
    "2022-12-06": {
        id: "tt0113117",
        emojiText: "🇫🇷💋"
    },
    "2022-12-07": {
        id: "tt0039536",
        emojiText: "💋💀"
    },
    "2022-12-08": {
        id: "tt0050083",
        emojiText: "12😡👨🏻"
    },
    "2022-12-09": {
        id: "tt1602620",
        emojiText: "👴🏻❤👵🏻"
    },
    "2022-12-10": {
        id: "tt0133152",
        emojiText: "🌍🐵🐵🐵"
    },
    "2022-12-11": {
        id: "tt0120338",
        emojiText: "🛳🌊💥"
    },
    "2022-12-12": {
        id: "tt0157503",
        emojiText: "💧💀👸🏻✨"
    },
    "2022-12-13": {
        id: "tt0161081",
        emojiText: "❓🛌🏻⬇"
    },
    "2022-12-14": {
        id: "tt0163187",
        emojiText: "🏃🏻👰🏻"
    },
    "2022-12-15": {
        id: "tt0175142",
        emojiText: "😱🎬"
    },
    "2022-12-16": {
        id: "tt0448694",
        emojiText: "😺👢"
    },
    "2022-12-17": {
        id: "tt4593060",
        emojiText: "👃🏻➡➡➡"
    },
    "2022-12-18": {
        id: "tt0044706",
        emojiText: "⬆🕛"
    },
    "2022-12-19": {
        id: "tt0250687",
        emojiText: "🐭🏃🏻🏃🏻🏃🏻"
    },
    "2022-12-20": {
        id: "tt0259324",
        emojiText: "👻👨🏻🐎"
    },
    "2022-12-21": {
        id: "tt0259711",
        emojiText: "🍦⛅"
    },
    "2022-12-22": {
        id: "tt0265086",
        emojiText: "⬛🐦⬇"
    },
    "2022-12-23": {
        id: "tt1210819",
        emojiText: "1👨🏻🐴"
    },
    "2022-12-24": {
        id: "tt0960731",
        emojiText: "😴📖"
    },
    "2022-12-25": {
        id: "tt0358273",
        emojiText: "🚶🏻➡➖➖"
    },
    "2022-12-26": {
        id: "tt0360717",
        emojiText: "👑🐵😡🏬"
    },
    "2022-12-27": {
        id: "tt0366548",
        emojiText: "😃👣"
    },
    "2022-12-28": {
        id: "tt0371746",
        emojiText: "ࠦ👨🏻"
    },
    "2022-12-29": {
        id: "tt0377471",
        emojiText: "🐝🆒"
    },
    "2022-12-30": {
        id: "tt0389790",
        emojiText: "🐝🎬"
    },
    "2022-12-31": {
        id: "tt0396269",
        emojiText: "👰🏻🤵🏻💥💥"
    },
    "2023-01-01": {
        id: "tt0401792",
        emojiText: "😈😈😈🌇"
    },
    "2023-01-02": {
        id: "tt0409459",
        emojiText: "⌚👨🏽👨🏽"
    },
    "2023-01-03": {
        id: "tt0415778",
        emojiText: "🔚🎮"
    },
    "2023-01-04": {
        id: "tt0417148",
        emojiText: "🐍🐍🛩"
    },
    "2023-01-05": {
        id: "tt0441773",
        emojiText: "👊🏻💥🐼"
    },
    "2023-01-06": {
        id: "tt0448157",
        emojiText: "🖐🏻🐓"
    },
    "2023-01-07": {
        id: "tt0450259",
        emojiText: "💉💎"
    },
    "2023-01-08": {
        id: "tt0454876",
        emojiText: "👳🏻🚣🏻♂🐅"
    },
    "2023-01-09": {
        id: "tt0472181",
        emojiText: "🔵👴🏻👧🏻👦🏻"
    },
    "2023-01-10": {
        id: "tt0499549",
        emojiText: "🔵👽"
    },
    "2023-01-11": {
        id: "tt0800369",
        emojiText: "⚡🔨💪🏻"
    },
    "2023-01-12": {
        id: "tt0879870",
        emojiText: "🍜🙏🏻❤"
    },
    "2023-01-13": {
        id: "tt0942385",
        emojiText: "🌴🍹⚡☔"
    },
    "2023-01-14": {
        id: "tt0961097",
        emojiText: "👹➡🇫🇷🗼"
    },
    "2023-01-15": {
        id: "tt0978621",
        emojiText: "🏃🏻💥⬅🚂"
    },
    "2023-01-16": {
        id: "tt1605783",
        emojiText: "🌃🕛🇫🇷🗼"
    },
    "2023-01-17": {
        id: "tt1074638",
        emojiText: "⛅🍃"
    },
    "2023-01-18": {
        id: "tt2103264",
        emojiText: "👑🇨🇳"
    },
    "2023-01-19": {
        id: "tt1276104",
        emojiText: "👨🏻🔫👦🏻🔄"
    },
    "2023-01-20": {
        id: "tt1289401",
        emojiText: "👻👻🔫"
    },
    "2023-01-21": {
        id: "tt1416801",
        emojiText: "🔫➡🍀👨🏻"
    },
    "2023-01-22": {
        id: "tt1424381",
        emojiText: "🐆🐅🐊"
    },
    "2023-01-23": {
        id: "tt14403842",
        emojiText: "🔟☔"
    },
    "2023-01-24": {
        id: "tt1860353",
        emojiText: "🐌💨"
    },
    "2023-01-25": {
        id: "tt1637725",
        emojiText: "🐻❤🍻🚬"
    },
    "2023-01-26": {
        id: "tt1790885",
        emojiText: "0🌌30"
    },
    "2023-01-27": {
        id: "tt1798709",
        emojiText: "👨🏻👓❤📱"
    },
    "2023-01-28": {
        id: "tt1885300",
        emojiText: "👌🏻👨🏻⬇"
    },
    "2023-01-29": {
        id: "tt0054215",
        emojiText: "🔪🚿"
    },
    "2023-01-30": {
        id: "tt1981677",
        emojiText: "🎤🎼💯"
    },
    "2023-01-31": {
        id: "tt0099785",
        emojiText: "🏠😱🎄👱🏻"
    },
    "2023-02-01": {
        id: "tt2334879",
        emojiText: "◽🏠⬇"
    },
    "2023-02-02": {
        id: "tt0021749",
        emojiText: "🏙💡💡"
    },
    "2023-02-03": {
        id: "tt0105236",
        emojiText: "🚧🌊🚧🐶"
    },
    "2023-02-04": {
        id: "tt1049413",
        emojiText: "🏠🎈🎈🎈"
    },
    "2023-02-05": {
        id: "tt0068646",
        emojiText: "🇮🇹😇👴🏻"
    },
    "2023-02-06": {
        id: "tt0109830",
        emojiText: "🏃🏻🍫🍤"
    },
    "2023-02-07": {
        id: "tt0137523",
        emojiText: "👊🏻♣"
    },
    "2023-02-08": {
        id: "tt0266543",
        emojiText: "🔎🐠"
    },
    "2023-02-09": {
        id: "tt1375666",
        emojiText: "💡➡😴"
    },
    "2023-02-10": {
        id:  "tt0114709",
        emojiText:  "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2023-02-11": {
        id:  "tt0167260",
        emojiText:  "💍🌋🧙‍♂️👑"
    },
    "2023-02-12": {
        id:  "tt6139732",
        emojiText:  "🧞‍♂️🪔🐒👸🤴"
    },
    "2023-02-13": {
        id:  "tt0211915",
        emojiText:  "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2023-02-14": {
        id:  "tt1745960",
        emojiText:  "✈️🇺🇸🕶️"
    },
    "2023-02-15": {
        id:  "tt0109830",
        emojiText:  "🏃🍫🦐"
    },
    "2023-02-16": {
        id:  "tt0120382",
        emojiText:  "🙍‍♂️🎥⛵😨📺"
    },
    "2023-02-17": {
        id:  "tt4633694",
        emojiText:  "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2023-02-18": {
        id:  "tt0090605",
        emojiText:  "👽🤰🩸😱"
    },
    "2023-02-19": {
        id:  "tt0062622",
        emojiText:  "👁️🖥️🛰️🚀"
    },
    "2023-02-20": {
        id:  "tt0054215",
        emojiText:  "🔪🚿🧓"
    },
    "2023-02-21": {
        id:  "tt0101414",
        emojiText:  "🌹👸🧌"
    },
    "2023-02-22": {
        id:  "tt0264464",
        emojiText:  "🏃‍♂️✈️💰🏃"
    },
    "2023-02-23": {
        id:  "tt0045152",
        emojiText:  "☔🎤😃"
    },
    "2023-02-24": {
        id:  "tt0107290",
        emojiText:  "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2023-02-25": {
        id:  "tt0382932",
        emojiText:  "🐭💆‍♂️👨‍🍳🍲"
    },
    "2023-02-26": {
        id:  "tt10648342",
        emojiText:  "🦸🔨❤️➕⛈️"
    },
    "2023-02-27": {
        id:  "tt0482571",
        emojiText:  "🏃🚪🎩🚪🏃▶️👬"
    },
    "2023-02-28": {
        id:  "tt2380307",
        emojiText:  "🎸💀👦🇲🇽"
    },
    "2023-03-01": {
        id:  "tt0107048",
        emojiText:  "🔄⏰🦔"
    },
    "2023-03-02": {
        id:  "tt0133093",
        emojiText:  "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2023-03-03": {
        id:  "tt1130884",
        emojiText:  "👮‍♂️💊🏝️😨"
    },
    "2023-03-04": {
        id:  "tt0114369",
        emojiText:  "😋😛😴🤢😡😎🤤👀"
    },
    "2023-03-05": {
        id:  "tt5311514",
        emojiText:  "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2023-03-06": {
        id:  "tt0119217",
        emojiText:  "🧹🏫👨‍🏫🧠🍎"
    },
    "2023-03-07": {
        id:  "tt12412888",
        emojiText:  "🦔👟✌️"
    },
    "2023-03-08": {
        id:  "tt0088763",
        emojiText:  "🚗🔙⌚👨‍🔬🙍"
    },
    "2023-03-09": {
        id:  "tt0435761",
        emojiText:  "👨‍🚀🤠🏫🍓🧸"
    },
    "2023-03-10": {
        id:  "tt0116629",
        emojiText:  "🇺🇸🎆👽👊"
    },
    "2023-03-11": {
        id:  "tt0325980",
        emojiText:  "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2023-03-12": {
        id:  "tt6467266",
        emojiText:  "🐷🦍🦁🎹🎧🦔"
    },
    "2023-03-13": {
        id:  "tt1160419",
        emojiText:  "👩‍👦🗡️⏳🐛🪐"
    },
    "2023-03-14": {
        id:  "tt0066921",
        emojiText:  "👁️🎩🦯🥛🍊"
    },
    "2023-03-15": {
        id:  "tt1049413",
        emojiText:  "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2023-03-16": {
        id:  "tt0120338",
        emojiText:  "💑🚢🧊🥶👵"
    },
    "2023-03-17": {
        id:  "tt0050083",
        emojiText:  "😠😠😠😠😠😠😠😠😠😠😠😠"
    },
    "2023-03-18": {
        id:  "tt0073195",
        emojiText:  "🦈😱"
    },
    "2023-03-19": {
        id:  "tt0137523",
        emojiText:  "🥊♣️🧼"
    },
    "2023-03-20": {
        id:  "tt0088247",
        emojiText:  "🤖🕶️🔫"
    },
    "2023-03-21": {
        id:  "tt0110413",
        emojiText:  "🧔🔫🥛👧"
    },
    "2023-03-22": {
        id:  "tt0110912",
        emojiText:  "💼🕺💉💃☕⌚"
    },
    "2023-03-23": {
        id:  "tt0083658",
        emojiText:  "🗡️🏃‍♂️"
    },
    "2023-03-24": {
        id:  "tt4729430",
        emojiText:  "📫🧓🔨🧸🛷"
    },
    "2023-03-25": {
        id:  "tt2382320",
        emojiText:  "🚫🕐💀"
    },
    "2023-03-26": {
        id:  "tt1201607",
        emojiText:  "👦⚡☠️⚔️2️⃣"
    },
    "2023-03-27": {
        id:  "tt0095016",
        emojiText:  "🗼🎄👨🔫"
    },
    "2023-03-28": {
        id:  "tt0041959",
        emojiText:  "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2023-03-29": {
        id:  "tt0070047",
        emojiText:  "✝️🤮🙏"
    },
    "2023-03-30": {
        id:  "tt0266697",
        emojiText:  "👱‍♀️⛩️⚔️"
    },
    "2023-03-31": {
        id:  "tt0081505",
        emojiText:  "🪓😈🛀"
    },
    "2023-04-01": {
        id:  "tt10298810",
        emojiText:  "👨‍🚀🌌"
    },
    "2023-04-02": {
        id:  "tt0167404",
        emojiText:  "👁️👀💀🧑‍🤝‍🧑"
    },
    "2023-04-03": {
        id:  "tt9032400",
        emojiText:  "🦸‍♂️🌍♾️"
    },
    "2023-04-04": {
        id:  "tt0317705",
        emojiText:  "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2023-04-05": {
        id:  "tt6751668",
        emojiText:  "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2023-04-06": {
        id:  "tt0266543",
        emojiText:  "🔎🐟"
    },
    "2023-04-07": {
        id:  "tt0075148",
        emojiText:  "🥊"
    },
    "2023-04-08": {
        id:  "tt0112573",
        emojiText:  "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2023-04-09": {
        id:  "tt0816692",
        emojiText:  "🚀🌊⏲️🌽"
    },
    "2023-04-10": {
        id:  "tt0102926",
        emojiText:  "🤐🐑"
    },
    "2023-04-11": {
        id:  "tt0884328",
        emojiText:  "🌫️🛒🌫️"
    },
    "2023-04-12": {
        id:  "tt1375666",
        emojiText:  "💤🌀⤴️"
    },
    "2023-04-13": {
        id:  "tt0060196",
        emojiText:  "👍👎👹"
    },
    "2023-04-14": {
        id:  "tt0198781",
        emojiText:  "🟢👁️🟢👧👹"
    },
    "2023-04-15": {
        id:  "tt0892769",
        emojiText:  "⚫🐉🦷"
    },
    "2023-04-16": {
        id:  "tt0910970",
        emojiText:  "🌍🗑️🤖🌱❤️"
    },
    "2023-04-17": {
        id:  "tt8503618",
        emojiText:  "🇺🇸🎼🗽🎵🔫"
    },
    "2023-04-18": {
        id:  "tt0457430",
        emojiText:  "👁️👐👁️"
    },
    "2023-04-19": {
        id:  "tt0118715",
        emojiText:  "🎳🥛💼🩲"
    },
    "2023-04-20": {
        id:  "tt0169547",
        emojiText:  "🇺🇸💅🌹🌹🌹🌹🌹🌹"
    },
    "2023-04-21": {
        id:  "tt0056172",
        emojiText:  "🏇🏜️🗡️"
    },
    "2023-04-22": {
        id:  "tt1345836",
        emojiText:  "🦇🤵⬆️"
    },
    "2023-04-23": {
        id:  "tt0102926",
        emojiText:  "🔇🐏"
    },
    "2023-04-24": {
        id: "tt1375666",
        emojiText: "💡➡😴"
    },
    "2023-04-25": {
        id:  "tt0114709",
        emojiText:  "🥔🤠👨‍🚀🐊🐖🐶"
    },
    "2023-04-26": {
        id:  "tt0167260",
        emojiText:  "💍🌋🧙‍♂️👑"
    },
    "2023-04-27": {
        id:  "tt6139732",
        emojiText:  "🧞‍♂️🪔🐒👸🤴"
    },
    "2023-04-28": {
        id:  "tt0211915",
        emojiText:  "👩‍🦰🎠🗼🥖🇫🇷"
    },
    "2023-04-29": {
        id:  "tt1745960",
        emojiText:  "✈️🇺🇸🕶️"
    },
    "2023-04-30": {
        id:  "tt0109830",
        emojiText:  "🏃🍫🦐"
    },
    "2023-04-31": {
        id:  "tt0120382",
        emojiText:  "🙍‍♂️🎥⛵😨📺"
    },
    "2023-05-01": {
        id:  "tt4633694",
        emojiText:  "🕷️🦸🦹🦸‍♂️🦹‍♂️🦸‍♀️🦹‍♀️🎨✏️"
    },
    "2023-05-02": {
        id:  "tt0090605",
        emojiText:  "👽🤰🩸😱"
    },
    "2023-05-03": {
        id:  "tt0062622",
        emojiText:  "👁️🖥️🛰️🚀"
    },
    "2023-05-04": {
        id:  "tt0054215",
        emojiText:  "🔪🚿🧓"
    },
    "2023-05-05": {
        id:  "tt0101414",
        emojiText:  "🌹👸🧌"
    },
    "2023-05-06": {
        id:  "tt0264464",
        emojiText:  "🏃‍♂️✈️💰🏃"
    },
    "2023-05-07": {
        id:  "tt0045152",
        emojiText:  "☔🎤😃"
    },
    "2023-05-08": {
        id:  "tt0107290",
        emojiText:  "🦟💉🥚🦕🦖🚨🏃‍♂️"
    },
    "2023-05-09": {
        id:  "tt0382932",
        emojiText:  "🐭💆‍♂️👨‍🍳🍲"
    },
    "2023-05-10": {
        id:  "tt10648342",
        emojiText:  "🦸🔨❤️➕⛈️"
    },
    "2023-05-11": {
        id:  "tt0482571",
        emojiText:  "🏃🚪🎩🚪🏃▶️👬"
    },
    "2023-05-12": {
        id:  "tt2380307",
        emojiText:  "🎸💀👦🇲🇽"
    },
    "2023-05-13": {
        id:  "tt0107048",
        emojiText:  "🔄⏰🦔"
    },
    "2023-05-14": {
        id:  "tt0133093",
        emojiText:  "🕵️🕵️‍♀️🤜🤵🔌💊"
    },
    "2023-05-15": {
        id:  "tt1130884",
        emojiText:  "👮‍♂️💊🏝️😨"
    },
    "2023-05-16": {
        id:  "tt0114369",
        emojiText:  "😋😛😴🤢😡😎🤤👀"
    },
    "2023-05-17": {
        id:  "tt5311514",
        emojiText:  "👦💬⁉️↔️⁉️🗨️👧🗾🌊☄️"
    },
    "2023-05-18": {
        id:  "tt0119217",
        emojiText:  "🧹🏫👨‍🏫🧠🍎"
    },
    "2023-05-19": {
        id:  "tt12412888",
        emojiText:  "🦔👟✌️"
    },
    "2023-05-20": {
        id:  "tt0088763",
        emojiText:  "🚗🔙⌚👨‍🔬🙍"
    },
    "2023-05-21": {
        id:  "tt0435761",
        emojiText:  "👨‍🚀🤠🏫🍓🧸"
    },
    "2023-05-22": {
        id:  "tt0116629",
        emojiText:  "🇺🇸🎆👽👊"
    },
    "2023-05-23": {
        id:  "tt0325980",
        emojiText:  "🏴‍☠️🌊💀⚔️⚫📿"
    },
    "2023-05-24": {
        id:  "tt6467266",
        emojiText:  "🐷🦍🦁🎹🎧🦔"
    },
    "2023-05-25": {
        id:  "tt1160419",
        emojiText:  "👩‍👦🗡️⏳🐛🪐"
    },
    "2023-05-26": {
        id:  "tt0066921",
        emojiText:  "👁️🎩🦯🥛🍊"
    },
    "2023-05-27": {
        id:  "tt1049413",
        emojiText:  "👴🧒🎈🏠🏞️🐕🔼"
    },
    "2023-05-28": {
        id:  "tt0120338",
        emojiText:  "💑🚢🧊🥶👵"
    },
    "2023-05-29": {
        id:  "tt0050083",
        emojiText:  "😠😠😠😠😠😠😠😠😠😠😠😠"
    },
    "2023-05-30": {
        id:  "tt0073195",
        emojiText:  "🦈😱"
    },
    "2023-05-31": {
        id:  "tt0137523",
        emojiText:  "🥊♣️🧼"
    },
    "2023-06-01": {
        id:  "tt0088247",
        emojiText:  "🤖🕶️🔫"
    },
    "2023-06-02": {
        id:  "tt0110413",
        emojiText:  "🧔🔫🥛👧"
    },
    "2023-06-03": {
        id:  "tt0110912",
        emojiText:  "💼🕺💉💃☕⌚"
    },
    "2023-06-04": {
        id:  "tt0083658",
        emojiText:  "🗡️🏃‍♂️"
    },
    "2023-06-05": {
        id:  "tt4729430",
        emojiText:  "📫🧓🔨🧸🛷"
    },
    "2023-06-06": {
        id:  "tt2382320",
        emojiText:  "🚫🕐💀"
    },
    "2023-06-07": {
        id:  "tt1201607",
        emojiText:  "👦⚡☠️⚔️2️⃣"
    },
    "2023-06-08": {
        id:  "tt0095016",
        emojiText:  "🗼🎄👨🔫"
    },
    "2023-06-09": {
        id:  "tt0041959",
        emojiText:  "🧍‍♂️🧍‍♂️🧍‍♂️⬅️"
    },
    "2023-06-10": {
        id:  "tt0070047",
        emojiText:  "✝️🤮🙏"
    },
    "2023-06-11": {
        id:  "tt0266697",
        emojiText:  "👱‍♀️⛩️⚔️"
    },
    "2023-06-12": {
        id:  "tt0081505",
        emojiText:  "🪓😈🛀"
    },
    "2023-06-13": {
        id:  "tt10298810",
        emojiText:  "👨‍🚀🌌"
    },
    "2023-06-14": {
        id:  "tt0167404",
        emojiText:  "👁️👀💀🧑‍🤝‍🧑"
    },
    "2023-06-15": {
        id:  "tt9032400",
        emojiText:  "🦸‍♂️🌍♾️"
    },
    "2023-06-16": {
        id:  "tt0317705",
        emojiText:  "👨‍👩‍👧‍👦🦸ℹ️"
    },
    "2023-06-17": {
        id:  "tt6751668",
        emojiText:  "👨‍👩‍👧‍👦💸🇰🇷"
    },
    "2023-06-18": {
        id:  "tt0266543",
        emojiText:  "🔎🐟"
    },
    "2023-06-19": {
        id:  "tt0075148",
        emojiText:  "🥊"
    },
    "2023-06-20": {
        id:  "tt0112573",
        emojiText:  "🏴󠁧󠁢󠁳󠁣󠁴󠁿⚔️😠☠️"
    },
    "2023-06-21": {
        id:  "tt0816692",
        emojiText:  "🚀🌊⏲️🌽"
    },
    "2023-06-22": {
        id:  "tt0102926",
        emojiText:  "🤐🐑"
    },
    "2023-06-23": {
        id:  "tt0884328",
        emojiText:  "🌫️🛒🌫️"
    },
    "2023-06-24": {
        id:  "tt1375666",
        emojiText:  "💤🌀⤴️"
    }
};