import { Twemoji } from "react-emoji-render";
import * as base64 from "base-64";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { DateTime, Interval } from "luxon";
import {
    compressToEncodedURIComponent,
    decompressFromEncodedURIComponent,
} from "lz-string";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    Link,
    Navigate,
    Route,
    Routes,
    useNavigate,
    useParams,
} from "react-router-dom";
import Select from "react-select";
import { Flip, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as utf8 from "utf8";
import { allMovies, Movie, pickRandomMovie } from "./movies";
import dayjs from "dayjs";
import { Account } from "./pages/Account/Account";
import UserContext, { UserProvider } from "./UserProvider";
import { EmoviToGuess } from "./interfaces/EmoviToGuess";
import { DAILY_EMOVI } from "./data/games";
import { StatsPage } from "./pages/Stats/Stats";
import { saveStatsToDB } from "./services/stats";
import { getDayStringNegOne } from "./services/utilities";

const GUESSES_KEY = "guesses";

function decode(s: string) {
    return JSON.parse(utf8.decode(base64.decode(decodeURIComponent(s))));
}

function encode(o: any) {
    return encodeURIComponent(base64.encode(utf8.encode(JSON.stringify(o))));
}

function buildShareUrl(emoviToGuess: EmoviToGuess) {
    const encodedEmovi = encode(emoviToGuess);
    return `${window.location.origin}/guess/${encodedEmovi}`;
}

export function getDayString(shiftDayCount?: number) {
    return DateTime.now()
        .plus({ days: shiftDayCount ?? 0 })
        .toFormat("yyyy-MM-dd");
}

const START_DATE = DateTime.fromISO("2022-07-17");


const PromoDateDiff = (dt1: any, dt2 = new Date()) => {
    const ret = { days: 0, months: 0, years: 0 };

    if (dt1 === dt2) return ret;
    if (dt1 > dt2) {
        const dtmp = dt2;
        dt2 = dt1;
        dt1 = dtmp;
    }

    const year1 = dt1.getFullYear();
    const year2 = dt2.getFullYear();

    const month1 = dt1.getMonth();
    const month2 = dt2.getMonth();

    const day1 = dt1.getDate();
    const day2 = dt2.getDate();
    ret["years"] = year2 - year1;
    ret["months"] = month2 - month1;
    ret["days"] = day2 - day1;

    if (ret["days"] < 0) {
        const dtmp1 = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1, 0, 0, -1);

        const numDays = dtmp1.getDate();

        ret["months"] -= 1;
        ret["days"] += numDays;
    }

    if (ret["months"] < 0) {
        ret["months"] += 12;
        ret["years"] -= 1;
    }

    return ret;
};

interface PromoProps {
    label: string;
    link: string;
}

export const getPromotionalInfo = (): PromoProps => {
    const PromotionalStartDate = new Date(2022, 10, 1);

    const PromotionalInfo = [
        {
            link: "https://semantle.com/junior",
            label: "Try Semantle Jr.!",
        },
        {
            link: "https://wheretaken.teuteuf.fr",
            label: "Try WhereTaken 🌎",
        },
        {
            link: "https://wheretakenusa.teuteuf.fr",
            label: "Try WhereTaken 🇺🇸",
        },
        {
            link: "https://www.thewordfinder.com/word-connection/",
            label: "Try Word Connection",
        },
        {
            link: "https://www.thewordfinder.com/daily-anagram/",
            label: "Try Daily Anagram",
        },
    ];
    const d = PromoDateDiff(PromotionalStartDate);
    let result = PromotionalInfo[0];
    if (d.months > 0) {
        const shuffledPromotions = PromotionalInfo.map((value) => ({
            value,
            sort: Math.random(),
        }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
        result = shuffledPromotions[0];
    }
    return result;
};


function MovieCard({ movie }: { movie: Movie }) {
    return (
        <div className="flex flex-col items-center">
            <img className="h-64" src={movie.image} alt="Movie's poster" />
            <p className="text-lg font-bold text-center">{movie.title}</p>
        </div>
    );
}

function getSavedGuesses() {
    const guesses: Record<string,
        { movieGuessed: boolean; invalidGuessIds: string[] } | undefined> = JSON.parse(localStorage.getItem(GUESSES_KEY) ?? "{}");

    return guesses;
}

function GuessAEmovi({
    emoviToGuess,
    dailyNumber,
    dayString,
}: {
    emoviToGuess: EmoviToGuess;
    dailyNumber?: number;
    dayString?: string;
}) {
    const { userDetails } = useContext(UserContext);

    const movieToGuess = useMemo(() => {
        return emoviToGuess && allMovies.find((m) => m.id === emoviToGuess.id);
    }, [emoviToGuess]);

    useEffect(() => {
        console.log(emoviToGuess);
    }, [emoviToGuess]);

    const selectOptions = useMemo(() => {
        return allMovies.map((m) => ({
            value: m.id,
            label: m.title,
        }));
    }, []);

    const [selectedOption, setSelectedOption] = useState<{
        value: string;
        label: string;
    } | null>(null);

    const initialSavedGuess = useMemo(() => {
        if (!dayString) {
            return undefined;
        }

        const savedGuesses = getSavedGuesses();
        return savedGuesses[dayString];
    }, [dayString]);
    const [invalidGuessIds, setInvalidGuessIds] = useState<string[]>(
        initialSavedGuess?.invalidGuessIds ?? []
    );
    const [movieGuessed, setMovieGuessed] = useState<boolean>(
        initialSavedGuess?.movieGuessed ?? false
    );
    const [movieFailed, setMovieFailed] = useState<boolean>(
        false
    );

    const selectMovieRef = useRef<any>(null);
    const handleGetHint = useCallback(() => {
        setInvalidGuessIds((prev) => {
            const updatedInvalidGuessIds = [...prev, ""];
            if (dayString) {
                const guesses = getSavedGuesses();
                guesses[dayString]!.invalidGuessIds = updatedInvalidGuessIds;
                localStorage.setItem('guesses', JSON.stringify(guesses));
            }
            if(updatedInvalidGuessIds.length >= 3) {
                setMovieFailed(true);
            }
            return updatedInvalidGuessIds;
        });
    }, [dayString]);
    const handleGuess = useCallback(() => {

        if (!selectedOption) {
            selectMovieRef.current?.focus();
            return;
        }
        if (emoviToGuess && emoviToGuess.id === selectedOption?.value) {
            setMovieGuessed((prev) => {
                if (emoviToGuess && emoviToGuess.id === selectedOption?.value) {
                    toast.success("You guessed it!");

                    if (dayString) {
                        const guesses = getSavedGuesses();
                        guesses[dayString]!.movieGuessed = true;
                        localStorage.setItem('guesses', JSON.stringify(guesses));
                    }
                    return true;
                }
                return prev;
            });

            toast.success("You guessed it!");
        } else {
            console.log('setting invalid guess...');
            setInvalidGuessIds((prev) => {
                const updatedInvalidGuessIds = [...prev, selectedOption?.value];
                if (dayString) {
                    const guesses = getSavedGuesses();
                    guesses[dayString]!.invalidGuessIds = updatedInvalidGuessIds;
                    localStorage.setItem('guesses', JSON.stringify(guesses));
                }
                if(updatedInvalidGuessIds.length >= 3) {
                    setMovieFailed(true);
                }
                return updatedInvalidGuessIds;
            });
            toast.error("Wrong movie!");
            setSelectedOption(null);
        }

    }, [dayString, emoviToGuess, selectedOption]);

    const [isInitialRender, setIsInitialRender] = useState(true);
    useEffect(() => {
        if (isInitialRender) {
            console.log("isInitialRender");
            if (!dayString) {
                return;
            }

            const guesses = getSavedGuesses();

            if (!guesses[dayString]) {
                guesses[dayString] = {
                    movieGuessed: false,
                    invalidGuessIds: [],
                };
            }

            guesses[dayString]!.movieGuessed = movieGuessed;
            guesses[dayString]!.invalidGuessIds = invalidGuessIds;
            localStorage.setItem('guesses', JSON.stringify(guesses));

            setIsInitialRender(false);
        }
    }, [dayString, movieGuessed, invalidGuessIds, isInitialRender]);


    useEffect(() => {
        // Game over, try and save stats
        if (movieGuessed || movieFailed) {
            saveStatsToDB(userDetails);
        }
    }, [movieGuessed, userDetails, movieFailed]);

    const { statsUpdated } = useContext(UserContext);

    useEffect(() => {
        const guesses = getSavedGuesses();
        const dayString = getDayStringNegOne();
        if(guesses[dayString]) {
            const currentGuess = guesses[dayString];
            if(currentGuess && currentGuess.movieGuessed) {
                setMovieGuessed(true);
            }
            if(currentGuess && currentGuess.invalidGuessIds.length >= 3) {
                setMovieFailed(true);
            }
        }
        console.log("stats have been updated...");

    }, [statsUpdated]);

    const shareText = useMemo(() => {
        if (!emoviToGuess || !movieToGuess) {
            return "";
        }

        return [
            `#Emovi 🎬${dailyNumber ? ` #${dailyNumber}` : ""}`,
            emoviToGuess.emojiText,
            "🟥".repeat(invalidGuessIds.length) +
            (movieGuessed ? "🟩" : "") +
            "⬜".repeat(Math.max(MAX_TRIES - invalidGuessIds.length - 1, 0)),
            dayString ? window.location.origin : buildShareUrl(emoviToGuess),
        ].join("\n");
    }, [
        dailyNumber,
        dayString,
        emoviToGuess,
        invalidGuessIds.length,
        movieGuessed,
        movieToGuess,
    ]);

    return (
        <div className="flex flex-col items-center gap-2 w-full">
            <div className="flex flex-col items-center w-full">
                <p className="text-lg font-bold text-center">Guess this movie:</p>
                {emoviToGuess && (
                    <p className="text-3xl text-center">
                        <Twemoji
                            text={emoviToGuess.emojiText}
                            options={{ className: "inline-block" }}
                        />
                    </p>
                )}
                {emoviToGuess.author && (
                    <p className="text-center">
                        by{" "}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700 font-semibold"
                            href={`https://twitter.com/${emoviToGuess.author}`}
                        >
                            {emoviToGuess.author}
                        </a>
                    </p>
                )}
            </div>
            {!movieGuessed && !movieFailed ? (
                <div className="flex flex-col gap-4 items-center w-full">
                    <Select
                        ref={selectMovieRef}
                        openMenuOnFocus
                        openMenuOnClick
                        className="w-full"
                        options={selectOptions}
                        onChange={setSelectedOption}
                        value={selectedOption}
                    />
                    <div className="flex flex-row-reverse gap-2 w-full ">
                        <button
                            onClick={handleGuess}
                            className="bg-blue-500 hover:bg-blue-700 disabled:bg-gray-300 text-white font-bold py-4 px-4 rounded basis-2/3"
                        >
                            Guess! ({invalidGuessIds.length + 1} / {MAX_TRIES})
                        </button>
                        <button
                            onClick={handleGetHint}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-4 rounded basis-1/3"
                        >
                            {invalidGuessIds.length >= MAX_TRIES - 1
                                ? "Give up..."
                                : "Get a hint..."}
                        </button>
                    </div>
                    {invalidGuessIds.length > 0 && (
                        <div className="w-full flex justify-start items-start">
                            <div className="flex-shrink-0 basis-32 font-bold whitespace-nowrap">
                                Hint #1 - Year:
                            </div>
                            <div className="col-span-2">{movieToGuess?.year}</div>
                        </div>
                    )}
                    {invalidGuessIds.length > 1 && (
                        <div className="w-full flex justify-start items-start">
                            <div className="flex-shrink-0 basis-32 font-bold whitespace-nowrap">
                                Hint #2 - Crew:
                            </div>
                            <div className="col-span-2">{movieToGuess?.crew}</div>
                        </div>
                    )}
                    <div className="mt-16 flex flex-col gap-2 items-center">
                        {!dailyNumber && (
                            <>
                                <Link
                                    to="/"
                                    className="text-blue-500 hover:text-blue-700 font-bold"
                                >
                                    Go to the daily Emovi
                                </Link>
                                <p>or</p>
                            </>
                        )}
                        <Link
                            to="/make"
                            className="text-blue-500 hover:text-blue-700 font-bold"
                        >
                            Create your own Emovi
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-2 text-center w-full">
                    {movieToGuess && <MovieCard movie={movieToGuess} />}
                    <div>
                        {movieGuessed ? (
                            <>
                                <p>Well done! You guessed the movie!</p>
                                <p>In {invalidGuessIds.length + 1} {invalidGuessIds.length + 1 === 1 ? 'guess' : 'guesses'}!</p>
                            </>
                        ) : (
                            <p>You failed to guess the movie...</p>
                        )}
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <CopyToClipboard
                            text={shareText}
                            onCopy={() => toast("Result copied to clipboard")}
                            options={{
                                format: "text/plain",
                            }}
                        >
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
                                Share your result
                            </button>
                        </CopyToClipboard>
                        <Link
                            to="/make"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                        >
                            Create your Emovi
                        </Link>
                        {!dailyNumber && (
                            <Link
                                to="/"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                            >
                                Go to the daily Emovi
                            </Link>
                        )}
                    </div>
                    {dayString && (
                        <div className="py-4 font-bold flex flex-col">
                            <span><Link to={'/stats'} className="text-blue-500 hover:text-blue-600">See full stats</Link></span>

                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

function DailyEmoviRoute() {

    const curerntString = getDayString();
    const dayString = dayjs(curerntString).subtract(1, 'day').format('YYYY-MM-DD');

    const dailyNumber =
        Interval.fromDateTimes(START_DATE, DateTime.fromISO(dayString)).length(
            "day"
        ) + 1;
    const emoviToGuess = DAILY_EMOVI[dayString];
    return emoviToGuess ? (
        <div className="flex flex-col gap-2">
            <p className="text-center font-bold text-lg">Emovi #{dailyNumber}</p>
            <GuessAEmovi
                emoviToGuess={emoviToGuess}
                dailyNumber={dailyNumber}
                dayString={dayString}
            />
        </div>
    ) : (
        <div className="flex flex-col gap-2">
            <p className="text-center font-bold">No daily emovi for today!</p>
            <p className="text-center">
                <Link
                    className="text-blue-500 hover:text-blue-700 font-bold"
                    to="/make"
                >
                    Create your own Emovi
                </Link>{" "}
                or check{" "}
                <a
                    className="text-blue-500 hover:text-blue-700 font-bold"
                    href="https://twitter.com/search/?q=%23MyEmovi"
                >
                    #MyEmovi
                </a>{" "}
                on Twitter!
            </p>
        </div>
    );
}
function ArchiveEmoviRoute() {
    const { date } = useParams<{ date: string }>();

    const migrationUrl = useMemo(() => {
        if (window.location.hostname !== "localhost") {
            return null;
        }
        const guesses = localStorage.getItem("guesses");
        if (!guesses) {
            return null;
        }
        const compressedGuesses = compressToEncodedURIComponent(guesses);
        return `https://play.emovi.fun/import/${compressedGuesses}`;
    }, []);

    // const curerntString = getDayString();
    const dayString = dayjs(date).format('YYYY-MM-DD');
    // const dailyEmovi = DAILY_EMOVI[predayString];
    const dailyNumber =
        Interval.fromDateTimes(START_DATE, DateTime.fromISO(dayString)).length(
            "day"
        ) + 1;
    const emoviToGuess = DAILY_EMOVI[dayString];
    return emoviToGuess ? (
        <div className="flex flex-col gap-2">
            <p className="text-center font-bold text-lg">Emovi #{dailyNumber}</p>
            <GuessAEmovi
                emoviToGuess={emoviToGuess}
                dailyNumber={dailyNumber}
                dayString={dayString}
            />
        </div>
    ) : (
        <div className="flex flex-col gap-2">
            <p className="text-center font-bold">No daily emovi for today!</p>
            <p className="text-center">
                <Link
                    className="text-blue-500 hover:text-blue-700 font-bold"
                    to="/make"
                >
                    Create your own Emovi
                </Link>{" "}
                or check{" "}
                <a
                    className="text-blue-500 hover:text-blue-700 font-bold"
                    href="https://twitter.com/search/?q=%23MyEmovi"
                >
                    #MyEmovi
                </a>{" "}
                on Twitter!
            </p>
        </div>
    );
}

function MakeAEmoviRoute() {
    const [movieToGuess, setMovieToGuess] = useState(pickRandomMovie());
    const [emojiText, setEmojiText] = useState("");
    const [validated, setValidated] = useState(false);

    const shareUrl = useMemo(
        () => buildShareUrl({ id: movieToGuess.id, emojiText }),
        [emojiText, movieToGuess.id]
    );

    const handleNewEmovi = useCallback(() => {
        setMovieToGuess(pickRandomMovie());
        setEmojiText("");
        setValidated(false);
    }, []);

    const shareText = useMemo(() => {
        return [
            "#Emovi 🎬 #MyEmovi",
            "Guess this movie:",
            emojiText,
            shareUrl,
        ].join("\n");
    }, [emojiText, shareUrl]);

    return (
        <div className="w-full">
            <div className="flex flex-col items-center gap-2 w-full">
                <MovieCard movie={movieToGuess} />
                {!validated && (
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={() => {
                            setMovieToGuess(pickRandomMovie());
                        }}
                    >
                        Pick another movie...
                    </button>
                )}
            </div>
            <div className="flex flex-col gap-1 items-center">
                <div className="flex flex-col gap-2 justify-center items-center grow shrink-0 basis-32">
                    <p className="text-xl text-center">
                        {emojiText ? (
                            <Twemoji
                                text={emojiText}
                                options={{ className: "inline-block text-3xl" }}
                            />
                        ) : (
                            <span> Describe movie with emoji!</span>
                        )}
                    </p>
                    {emojiText && !validated && (
                        <div className="flex gap-2">
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => {
                                    setEmojiText("");
                                }}
                            >
                                Clear
                            </button>
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => setValidated(true)}
                            >
                                Validate
                            </button>
                        </div>
                    )}
                </div>
                {!validated ? (
                    <Picker
                        set="twitter"
                        showSkinTones={false}
                        showPreview
                        onSelect={(e: any) => {
                            setEmojiText((prev) => prev + e.native);
                        }}
                    />
                ) : (
                    <div className="flex flex-col gap-1 w-full text-center">
                        <CopyToClipboard
                            text={shareText}
                            onCopy={() => toast("Emovi copied to clipboard")}
                            options={{
                                format: "text/plain",
                            }}
                        >
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                Share my Emovi
                            </button>
                        </CopyToClipboard>
                        <button
                            onClick={handleNewEmovi}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Create another Emovi
                        </button>
                        <Link
                            to="/"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Go to the daily Emovi
                        </Link>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700 font-bold text-center py-4"
                            href="https://docs.google.com/forms/d/e/1FAIpQLSevIwncXHGD4K4yPBHi3Z2P0ynzmqaWZccfCCnzitN5xei54g/viewform?usp=sf_link&hl=en"
                        >
                            Suggest a daily Emovi
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}

const MAX_TRIES = 3;

function GuessAEmoviRoute() {
    const { emovi: encodedEmovi } = useParams<{ emovi: string }>();
    const emoviToGuess: EmoviToGuess | undefined = useMemo(
        () => (encodedEmovi ? decode(encodedEmovi) : undefined),
        [encodedEmovi]
    );


    const dayString = getDayString();
    const predayString = dayjs(dayString).subtract(1, 'day').format('YYYY-MM-DD');
    const dailyEmovi = DAILY_EMOVI[predayString];



    if (
        dailyEmovi &&
        emoviToGuess &&
        dailyEmovi.id === emoviToGuess.id &&
        dailyEmovi.emojiText === emoviToGuess.emojiText
    ) {
        return <Navigate to="/" />;
    }

    return emoviToGuess ? (
        <GuessAEmovi emoviToGuess={emoviToGuess} />
    ) : (
        <div>Oops...</div>
    );
}

function ImportSaveRoute() {
    const navigate = useNavigate();
    const { save: encodedSave } = useParams<{ save: string }>();
    useEffect(() => {
        if (!encodedSave) {
            return;
        }

        const save = decompressFromEncodedURIComponent(encodedSave);
        if (!save) {
            return;
        }

        localStorage.setItem(GUESSES_KEY, save);
        navigate("/");
    }, [encodedSave, navigate]);

    return null;
}

const AppContent = () => {
    const { userDetails } = useContext(UserContext);

    const promotionalInfo = getPromotionalInfo();
    return (
        <>
            <header className="text-4xl font-bold text-center w-full border-b-2 border-gray-200 px-4">
                <div className="flex justify-between items-center w-full relative">
                    <Link to="/account">
                        <div className={`rounded-full ${userDetails.isPhotoValid ? 'w-7 h-7' : 'w-6 h-6'}`} style={{ width: 'max-content' }}>
                            <img
                                src={userDetails.isPhotoValid ? userDetails.photoURL : "/images/user.png"}
                                alt="user"
                                className="rounded-full"
                                style={{ display: 'block', margin: '0 auto', maxWidth: '100%', maxHeight: '100%' }}
                            />
                        </div>
                    </Link>
                    <div className="w-full my-1">
                        <Link to="/">
                            <Twemoji text="🎬 EMOVI 🎥" options={{ className: "inline-block" }} />
                        </Link>
                    </div>
                    <Link to="/stats">
                        <div className="w-6 h-6">
                            <img
                                src="/images/stats.png"
                                alt="stats"
                                style={{ display: 'block', margin: '0 auto', maxWidth: '100%', maxHeight: '100%' }}
                            />
                        </div>
                    </Link>
                </div>
            </header>
            <div className="flex justify-center items-center w-full pt-1 pb-1 lg:hidden xl:hidden md:hidden sm:visible" style={{ maxHeight: "50px" }}>
                <div id="adngin-top_banner-0"></div>
            </div>
            <div className="flex-grow w-full p-1">
                <Routes>
                    <Route path="/" element={<DailyEmoviRoute />} />
                    <Route path="/make" element={<MakeAEmoviRoute />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/stats" element={<StatsPage />} />
                    <Route path="/guess/:emovi" element={<GuessAEmoviRoute />} />
                    <Route path="/archive/:date" element={<ArchiveEmoviRoute />} />
                    <Route path="/import/:save" element={<ImportSaveRoute />} />
                </Routes>
            </div>
            <footer className="flex justify-center flex-col mt-8 mb-4 gap-2"
                style={{ paddingBottom: "100px !important" }}>
                <div className="flex justify-center items-center">
                    <Twemoji
                        text="❤️ EMOVI 🎥 ?"
                        className="flex gap-1 items-center justify-center mr-1 font-bold" />{" "}
                    -
                    <a
                        className="underline pl-2"
                        href={promotionalInfo.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="w-max">{"" + promotionalInfo.label}</div>
                    </a>
                </div>
            </footer>
        </>
    )
}

function App() {

    return (
        <UserProvider>
            <>
                <div className="flex justify-center flex-auto flex-row  relative overflow-hidden"
                    style={{ height: "100%", position: "relative" }}
                >

                    <div className="flex flex-col w-full max-w-lg relative">
                        <ToastContainer
                            hideProgressBar
                            position="top-center"
                            transition={Flip}
                            theme="light"
                            autoClose={2000}
                            bodyClassName="font-bold text-center"
                            toastClassName="flex justify-center m-2 max-w-full"
                            style={{ width: 500, maxWidth: "100%" }} />
                        <AppContent />
                        <div
                            className="absolute left-0 -translate-x-full snigel-sidev"
                            style={{ zIndex: 200, maxWidth: "330px" }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    zIndex: 10000,
                                    width: "300px",
                                    display: "flex",
                                    marginTop: "15px",
                                    marginRight: "30px",
                                    minHeight: "200px",
                                    justifyContent: "flex-end",
                                    pointerEvents: "all",
                                }}
                            >
                                <div id="adngin-sidebar_left-0"></div>
                            </div>
                        </div>
                        <div
                            className="absolute right-0 translate-x-full snigel-sidev"
                            style={{ zIndex: 200, maxWidth: "330px" }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    zIndex: 10000,
                                    marginTop: "15px",
                                    marginLeft: "30px",
                                    width: "300px",
                                    display: "flex",
                                    minHeight: "200px",
                                    justifyContent: "flex-start",
                                    pointerEvents: "all",
                                }}
                            >
                                <div id="adngin-sidebar_right-0"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </UserProvider>
    );
}

export default App;
