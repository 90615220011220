import dayjs from "dayjs";
import { DateTime, Interval } from "luxon";

const START_DATE = DateTime.fromISO("2022-07-17");

export function getDayStringNegOne() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const dateString = date.toISOString().split('T')[0]; 
  return dateString;
}


export const checkPhotoValidity = (photoURL: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = photoURL;
      image.onload = () => resolve(true);
      image.onerror = () => resolve(false);
    });
  };

  export function getGameNumber() {
    console.log('getting game numbner....')
    const curerntString = getDayStringNegOne();
    const dayString = dayjs(curerntString).subtract(1, 'day').format('YYYY-MM-DD');
    const dailyNumber =Interval.fromDateTimes(START_DATE, DateTime.fromISO(dayString)).length(
        "day"
    ) + 1;
    return dailyNumber;
  }

  export function hasPlayedToday(guessesString: string) {
    // Parse the string into an object
    const guesses = JSON.parse(guessesString);
  
    // Get current date
    const today = new Date();

    // Subtract one day from today
    const yesterday = new Date(today.getTime() - (24 * 60 * 60 * 1000));
  
    // Extract the year, month, and date from yesterday
    const year = yesterday.getFullYear();
    const month = yesterday.getMonth();
    const date = yesterday.getDate();

    return Object.keys(guesses).some(dateString => {
      const guessDate = new Date(dateString);
      return guessDate.getFullYear() === year 
          && guessDate.getMonth() === month
          && guessDate.getDate() === date;
    });
}