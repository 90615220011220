import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { initialStats } from "../../data/stats";
import { bars as Bar } from '../../interfaces/StatBars';
import './Stats.css';
import { getGameNumber } from "../../services/utilities";
import { Stats } from "../../interfaces/Stats";
import { calculateStats } from "../../services/stats";


export const StatsPage = () => {

    const [stats, setStats] = useState(initialStats);
    const [bars, setBars] = useState<Bar[]>([]);

    useEffect(() => {
        const newStats = calculateStats();
        setStats(newStats);
        const bars = newStats.guessDist.map((val: number, i: any) => ({ val, percent: (val / newStats.played) * 100 }));
        setBars(bars);
    }, []);

    return (
        <>
            <div className="px-4 md:px-0">
                <div className="mb-4 mt-4">
                    <Link to="/">
                        <span className="text-blue-500 hover:text-blue-400">
                            &#8592; Back
                        </span>
                    </Link>
                </div>
                <h2 className="text-xl font-bold">Your stats</h2>
                <div className="mt-3">
                    <div className="flex justify-center items-start gap-4 mt-4 text-black dark:!text-white">
                        <div className="flex flex-col justify-center items-center max-w-min text-center">
                            <p className="text-2xl font-bold">{stats.played}</p>
                            <p>Games<br />played</p>
                        </div>
                        <div className="flex flex-col justify-center items-center max-w-min text-center">
                            <p className="text-2xl font-bold">{isNaN(stats.winPercent) ? 0 : parseFloat(stats.winPercent.toFixed(1))}%</p>

                            <p>Win<br />%</p>
                        </div>
                        <div className="flex flex-col justify-center items-center max-w-min text-center">
                            <p className="text-2xl font-bold">{stats.currentStreak}</p>
                            <p>Current<br />Streak</p>
                        </div>
                        <div className="flex flex-col justify-center items-center max-w-min text-center">
                            <p className="text-2xl font-bold">{stats.maxStreak}</p>
                            <p>Max Streak</p>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h5 className="mb-2 font-bold text-black dark:!text-white text-center">Guess distribution</h5>
                        {bars.map((bar, i) => (
                            <div key={i} className="flex mb-1 font-bold">
                                <span className="text-black dark:!text-white number">{i < 3 ? i + 1 : '❌'}</span>
                                <div
                                    className="text-end ml-1 bar-margin dark:!text-black"
                                    style={{ flex: '0 1 ' + (bar.percent - 8) + '%' }}
                                >
                                    {bar.val}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}